import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query ProjectListQuery {
    projectList: sanityProjectList(_id: { regex: "/(drafts.|)projectList/" }) {
      title
      projects {
        project {
          ...ProjectInfo
        }
      }
    }
  }
`

const useProjects = filter => {
  const { projectList } = useStaticQuery(query)

  const projects = useMemo(() => {
    const list = projectList.projects.map(p => p.project)
    return filter ? filter(list) : list
  }, [projectList, filter])

  return projects
}

export default useProjects
