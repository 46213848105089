import React from 'react'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import clientConfig from '../../client-config'
import { Box } from 'rebass/styled-components'
import { breakpoints } from '../theme'

export default ({
  node,
  disableMobileResizing,
  fullWidthBreakpoint = 0,
  ...props
}) => {
  if (!node || !node.asset || !node.asset._id) {
    return null
  }
  // Full-width image
  const fullWidth = getFluidGatsbyImage(
    node.asset._id,
    { maxWidth: 1600 },
    clientConfig.sanity
  )
  const fluid =
    disableMobileResizing || node.disableMobileResizing
      ? fullWidth
      : [
          // Square img on mobile
          getFluidGatsbyImage(
            node.asset._id,
            { maxWidth: 700, maxHeight: 700 },
            clientConfig.sanity
          ),
          {
            // Full-width image from 1st breakpoint on up
            ...fullWidth,
            media: `(min-width: ${breakpoints[fullWidthBreakpoint]})`
          }
        ]
  return <Img fluid={fluid} alt={node.alt} {...props} />
}
